<template>
  <div>
    <van-tabbar v-model="active" route>
      <van-tabbar-item to="/" name="home" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item @click="tips()" name="search" icon="search">搜索</van-tabbar-item>
      <van-tabbar-item to="/history"  name="history" icon="friends-o">历史</van-tabbar-item>
      <van-tabbar-item  to="/setting"    name="setting" icon="setting-o">设置</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Toast } from 'vant';

export default {
  name: "Foot",
  data() {
    return {
      active:"home"
    }
  },
  methods:{
    tips(){
      Toast.success({
        "message":'暂未开通',
        'icon':'warning'
      })
    }
  }
}
</script>

<style scoped>

</style>