import {get} from '@/requests'

export function movieInfo(page = 1,keyword = "",history = 0) {
    let params = {page: page}
    if (keyword !== ""){
        params.keyword = keyword
    }
    if (history !== 0){
        params.history = history
    }
    return get("movie", params)
}


export function seriesInfo(mid, id) {
    return get("movie/series", {mid: mid, id: id})
}

//保存播放进度
export function saveProgress(mid, sid, time,duration) {
    return get("movie/progress", {movie_id: mid, sid: sid, time: time,duration:duration})
}


//获取播放进度
export function getProgress(mid) {
    return get("movie/getProgress", {movie_id: mid})
}